export const Gridtestimonial = () => import('../../components/Gridtestimonial.vue' /* webpackChunkName: "components/gridtestimonial" */).then(c => wrapFunctional(c.default || c))
export const Aboutnew = () => import('../../components/aboutnew.vue' /* webpackChunkName: "components/aboutnew" */).then(c => wrapFunctional(c.default || c))
export const Aboutus = () => import('../../components/aboutus.vue' /* webpackChunkName: "components/aboutus" */).then(c => wrapFunctional(c.default || c))
export const Accordian = () => import('../../components/accordian.vue' /* webpackChunkName: "components/accordian" */).then(c => wrapFunctional(c.default || c))
export const Accordianitem = () => import('../../components/accordianitem.vue' /* webpackChunkName: "components/accordianitem" */).then(c => wrapFunctional(c.default || c))
export const Bethechange = () => import('../../components/bethechange.vue' /* webpackChunkName: "components/bethechange" */).then(c => wrapFunctional(c.default || c))
export const Candidateservices = () => import('../../components/candidateservices.vue' /* webpackChunkName: "components/candidateservices" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const Clientservices = () => import('../../components/clientservices.vue' /* webpackChunkName: "components/clientservices" */).then(c => wrapFunctional(c.default || c))
export const Contactform = () => import('../../components/contactform.vue' /* webpackChunkName: "components/contactform" */).then(c => wrapFunctional(c.default || c))
export const Contactus = () => import('../../components/contactus.vue' /* webpackChunkName: "components/contactus" */).then(c => wrapFunctional(c.default || c))
export const Contactusform = () => import('../../components/contactusform.vue' /* webpackChunkName: "components/contactusform" */).then(c => wrapFunctional(c.default || c))
export const Customerservice = () => import('../../components/customerservice.vue' /* webpackChunkName: "components/customerservice" */).then(c => wrapFunctional(c.default || c))
export const Dualimage = () => import('../../components/dualimage.vue' /* webpackChunkName: "components/dualimage" */).then(c => wrapFunctional(c.default || c))
export const Featurecenteredgrid = () => import('../../components/featurecenteredgrid.vue' /* webpackChunkName: "components/featurecenteredgrid" */).then(c => wrapFunctional(c.default || c))
export const Fullimage = () => import('../../components/fullimage.vue' /* webpackChunkName: "components/fullimage" */).then(c => wrapFunctional(c.default || c))
export const Herosplitnavbar = () => import('../../components/herosplitnavbar.vue' /* webpackChunkName: "components/herosplitnavbar" */).then(c => wrapFunctional(c.default || c))
export const Homepagetop = () => import('../../components/homepagetop.vue' /* webpackChunkName: "components/homepagetop" */).then(c => wrapFunctional(c.default || c))
export const Icons = () => import('../../components/icons.vue' /* webpackChunkName: "components/icons" */).then(c => wrapFunctional(c.default || c))
export const Ir35 = () => import('../../components/ir35.vue' /* webpackChunkName: "components/ir35" */).then(c => wrapFunctional(c.default || c))
export const Justsomethings = () => import('../../components/justsomethings.vue' /* webpackChunkName: "components/justsomethings" */).then(c => wrapFunctional(c.default || c))
export const Kirbyimage = () => import('../../components/kirbyimage.vue' /* webpackChunkName: "components/kirbyimage" */).then(c => wrapFunctional(c.default || c))
export const Kirbylink = () => import('../../components/kirbylink.vue' /* webpackChunkName: "components/kirbylink" */).then(c => wrapFunctional(c.default || c))
export const Linkgrid = () => import('../../components/linkgrid.vue' /* webpackChunkName: "components/linkgrid" */).then(c => wrapFunctional(c.default || c))
export const Linksandtestimonial = () => import('../../components/linksandtestimonial.vue' /* webpackChunkName: "components/linksandtestimonial" */).then(c => wrapFunctional(c.default || c))
export const Meettheteam = () => import('../../components/meettheteam.vue' /* webpackChunkName: "components/meettheteam" */).then(c => wrapFunctional(c.default || c))
export const Moreservices = () => import('../../components/moreservices.vue' /* webpackChunkName: "components/moreservices" */).then(c => wrapFunctional(c.default || c))
export const Solutionssetusapart = () => import('../../components/solutionssetusapart.vue' /* webpackChunkName: "components/solutionssetusapart" */).then(c => wrapFunctional(c.default || c))
export const Standardheader = () => import('../../components/standardheader.vue' /* webpackChunkName: "components/standardheader" */).then(c => wrapFunctional(c.default || c))
export const Standardtext = () => import('../../components/standardtext.vue' /* webpackChunkName: "components/standardtext" */).then(c => wrapFunctional(c.default || c))
export const Standardvideo = () => import('../../components/standardvideo.vue' /* webpackChunkName: "components/standardvideo" */).then(c => wrapFunctional(c.default || c))
export const Testimonial = () => import('../../components/testimonial.vue' /* webpackChunkName: "components/testimonial" */).then(c => wrapFunctional(c.default || c))
export const Textblock = () => import('../../components/textblock.vue' /* webpackChunkName: "components/textblock" */).then(c => wrapFunctional(c.default || c))
export const Textimagegrid = () => import('../../components/textimagegrid.vue' /* webpackChunkName: "components/textimagegrid" */).then(c => wrapFunctional(c.default || c))
export const Whatsetsusapart = () => import('../../components/whatsetsusapart.vue' /* webpackChunkName: "components/whatsetsusapart" */).then(c => wrapFunctional(c.default || c))
export const Whoweare = () => import('../../components/whoweare.vue' /* webpackChunkName: "components/whoweare" */).then(c => wrapFunctional(c.default || c))
export const Whychoose = () => import('../../components/whychoose.vue' /* webpackChunkName: "components/whychoose" */).then(c => wrapFunctional(c.default || c))
export const NavNavigationDefault = () => import('../../components/nav/navigationDefault.vue' /* webpackChunkName: "components/nav-navigation-default" */).then(c => wrapFunctional(c.default || c))
export const NavNavigationFooter = () => import('../../components/nav/navigationFooter.vue' /* webpackChunkName: "components/nav-navigation-footer" */).then(c => wrapFunctional(c.default || c))
export const NavNavigationOffscreen = () => import('../../components/nav/navigationOffscreen.vue' /* webpackChunkName: "components/nav-navigation-offscreen" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
