
import NavigationOffscreen from "../components/nav/navigationOffscreen.vue";

import { mapState } from "vuex";
import { mapMutations } from "vuex";
import NavigationDefault from "../components/nav/navigationDefault.vue";
import NavigationFooter from "../components/nav/navigationFooter.vue";

const apiKey = process.env.GMAP_API_KEY;

export default {
  data() {
    return {
      scrollClass: "",
    };
  },
  name: "layoutDefault",
  mounted() {
    this.handleScroll();
  },
  methods: {
    handleScroll() {
      this.scrollClass = window.scrollY > 0 ? "scrollnav" : "";
    },
    ...mapMutations({
      setOffscreenState: "global/setOffscreenState",
    }),
  },
  created() {
    if (process.client) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  computed: {
    ...mapState({
      navigationFooter(state) {
        return state.global.content.navigationFooter;
      },
      navigationMain(state) {
        return state.global.content.navigationMain;
      },
      offscreenState(state) {
        return state.global.offscreenState;
      },
      facebook_url(state) {
        return state.global.content.facebook_url;
      },
      linkedin_url(state) {
        return state.global.content.linkedin_url;
      },
      twitter_url(state) {
        return state.global.content.twitter_url;
      },
      instagram_url(state) {
        return state.global.content.instagram_url;
      },
      footer_address(state) {
        return state.global.content.footer_address;
      },
      map_link(state) {
        return state.global.content.map_link;
      },
      email(state) {
        return state.global.content.email;
      },
      phone(state) {
        return state.global.content.phone;
      },
    }),
  },
  async fetch() {
    await this.$axios
      .$post(`/api/content`, {
        query: "site",
        select: {
          title: "site.title",
          categories: "site.categories",
          footer_address: "site.footer_address",
          facebook_url: "site.facebook_url",
          instagram_url: "site.instagram_url",
          linkedin_url: "site.linkedin_url",
          navigationMain: "site.navigation_main.toNavigationArray",
          navigationFooter: "site.navigation_footer.toNavigationArray",
          email: "site.email",
          phone: "site.phone",
        },
      })
      .then((resp) => {
        this.$store.commit("global/setGlobalContent", resp.result);
      })
      .catch((e) => {
        console.log("navigation bar error", e);
      });
  },
  components: { NavigationDefault, NavigationFooter, NavigationOffscreen },
};
